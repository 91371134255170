import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { Link } from "gatsby"

export default () => (
  <Layout>
    <SEO
      title="Accessibility Developer - Careers at D2i Technology"
      description="Join our team as an Accessibility Developer. 3-5 years of experience in web accessibility development with expertise in React, Angular, WordPress, and Drupal to create accessible web solutions compliant with WCAG 2.1/2.2 standards."
    />
    <section id="career-detail" className="career-detail">
      <div className="container" data-aos-todo="fade-up">
        <div className="section-title">
          <h1>Accessibility Developer</h1>
        </div>
        <div className="row mt-5">
          <div className="col-lg-8 mx-auto">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/careers">Careers</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Accessibility Developer
                </li>
              </ol>
            </nav>
            <div className="job-card">
              <div className="job-header">
                <h2>Job Description</h2>
                <Link
                  to="https://forms.gle/mCYwx55YhT6vA1cJ7"
                  target="_blank"
                  className="btn btn-primary"
                >
                  Apply Now
                </Link>
              </div>
              <p>
                <strong>Location</strong>: Noida / Delhi
                <br />
                <strong>Experience</strong>: 3-5 years
                <br />
                <strong>Employment Type</strong>: Full-time / Contractor
              </p>

              <h3>About the Role:</h3>
              <p>
                We are looking for an experienced Accessibility Developer with
                expertise in React, Angular, WordPress, and Drupal to ensure our
                web applications meet the highest accessibility standards. The
                ideal candidate will be responsible for developing accessible
                web solutions, conducting audits, and implementing best
                practices to comply with WCAG 2.1/2.2, ADA, and Section 508
                guidelines.
              </p>

              <h3>Key Responsibilities:</h3>
              <ul>
                <li>
                  Develop and optimize web applications in React, Angular,
                  WordPress, and Drupal with a focus on accessibility.
                </li>
                <li>
                  Ensure compliance with WCAG 2.1/2.2, ARIA, ADA, and Section
                  508 standards.
                </li>
                <li>
                  Perform accessibility audits using tools like axe DevTools,
                  WAVE, Lighthouse, and NVDA.
                </li>
                <li>
                  Implement ARIA roles, semantic HTML, and best practices to
                  enhance digital accessibility.
                </li>
                <li>
                  Work closely with designers and developers to create inclusive
                  user experiences.
                </li>
                <li>Conduct manual and automated accessibility testing.</li>
                <li>
                  Provide recommendations and remediation plans for
                  accessibility issues.
                </li>
                <li>
                  Stay updated on industry trends, legal requirements, and
                  accessibility innovations.
                </li>
              </ul>

              <h3>Required Skills & Qualifications:</h3>
              <ul>
                <li>
                  3-5 years of experience in web accessibility development.
                </li>
                <li>
                  Strong proficiency in React, Angular, WordPress, and Drupal.
                </li>
                <li>
                  In-depth knowledge of WCAG 2.1/2.2, ARIA, ADA, and Section 508
                  compliance.
                </li>
                <li>
                  Experience with accessibility testing tools like axe DevTools,
                  WAVE, Lighthouse, JAWS, and NVDA.
                </li>
                <li>
                  Strong understanding of semantic HTML, keyboard navigation,
                  and screen reader compatibility.
                </li>
                <li>
                  Hands-on experience optimizing UI components and themes for
                  accessibility in WordPress and Drupal.
                </li>
                <li>
                  Familiarity with CSS frameworks (Bootstrap, Tailwind) for
                  responsive and accessible design.
                </li>
                <li>
                  Experience with version control systems (Git) and Agile
                  development methodologies.
                </li>
              </ul>

              <h3>Preferred Qualifications:</h3>
              <ul>
                <li>
                  Certification in CPACC (Certified Professional in
                  Accessibility Core Competencies) or WAS (Web Accessibility
                  Specialist).
                </li>
                <li>
                  Experience working on government or enterprise accessibility
                  projects.
                </li>
                <li>
                  Knowledge of backend development for accessibility
                  enhancements.
                </li>
                <li>
                  Experience with additional JavaScript frameworks beyond React
                  and Angular.
                </li>
              </ul>

              <h3>Why Join Us?</h3>
              <ul>
                <li>
                  Opportunity to work on impactful projects that improve digital
                  accessibility.
                </li>
                <li>
                  Collaborative work environment with a focus on innovation.
                </li>
                <li>Competitive salary and benefits.</li>
                <li>Career growth and professional learning opportunities.</li>
              </ul>
            </div>
            <div className="apply-footer">
              <p>
                <span aria-hidden="true">🚀</span> Join us and help build a web
                that’s accessible to everyone!{" "}
                <span aria-hidden="true">🚀</span>
              </p>
              <Link
                to="https://forms.gle/mCYwx55YhT6vA1cJ7"
                target="_blank"
                className="btn btn-primary"
              >
                <span aria-hidden="true">👉</span> Apply Now!
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)
